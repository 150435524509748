import { useEffect, useMemo, useState } from 'react'
import {
  useGetClientAppQuery,
  useGetClientAppFormQuery,
  useUpdateClientAppMutation,
  type ClientApp,
  type ClientAppForm,
  type ClientAppPermission,
  useGetIntegrationTypesQuery,
} from '@sevenrooms/core/api'
import { useForm, useWatchMany, z } from '@sevenrooms/core/form'
import { Link, useNavigation } from '@sevenrooms/core/navigation'
import type { SelectOption } from '@sevenrooms/core/ui-kit/core'
import { Button, Checkbox, FormInput, Label, FormSelect, FormMultiSelect, FormNumberInput } from '@sevenrooms/core/ui-kit/form'
import { Box, HStack, Loader, VStack, notify } from '@sevenrooms/core/ui-kit/layout'
import { Header } from '@sevenrooms/core/ui-kit/typography'
import { copyToClipboard } from '@sevenrooms/core/utils'
import { routes } from '@sevenrooms/routes'
import { integrationTypesConstOptions } from '../ApiAudienceManager'
import type { ZodTypeAny } from 'zod'

function ClientAppEditForm(props: { data: ClientApp; formData: ClientAppForm; clientAppId: string }) {
  const { data, formData, clientAppId } = props

  const permissionKeyMap: { [key: string]: ClientAppPermission } = {}
  const permissionZodObject: { [key: string]: ZodTypeAny } = {
    read: z.boolean(),
    write: z.boolean(),
  }

  const defaultPermissions = useMemo(() => {
    const defaultPerm: { [key: string]: boolean } = {
      read: data.permissions.read,
      write: data.permissions.write,
    }

    for (const perm of formData.permissions) {
      let calculatedPermValue = true
      if (!formData.hasAllPermissions) {
        if (data.permissions.resourceOperationWhitelist && data.permissions.resourceOperationWhitelist.indexOf(perm.id) > -1) {
          calculatedPermValue = true
        } else if (data.permissions.resourceOperationBlacklist && data.permissions.resourceOperationBlacklist.indexOf(perm.id) > -1) {
          calculatedPermValue = false
        }
      }
      defaultPerm[perm.id] = calculatedPermValue
    }
    return defaultPerm
  }, [formData, data.permissions])
  const [updateClientApp, { isLoading: isUpdatingClientApp, isError: isErrorUpdating, isSuccess: isUpdateSuccess }] =
    useUpdateClientAppMutation()

  useEffect(() => {
    if (isUpdateSuccess) {
      notify({ content: 'Updated', type: 'success' })
    }
    if (isErrorUpdating) {
      notify({ content: 'Error updating client app', type: 'error' })
    }
  }, [isErrorUpdating, isUpdateSuccess])

  for (const perm of formData.permissions) {
    permissionKeyMap[perm.id] = perm
    permissionZodObject[perm.id] = z.boolean()
  }

  const editAudienceForm = z
    .object({
      name: z.string(),
      apiIntegrationType: z
        .string()
        .nullable()
        .transform(x => x ?? null),
      apiIntegrationTypeId: z
        .string()
        .nullable()
        .optional()
        .transform(x => x ?? null),
      integrationOwner: z
        .string()
        .nullable()
        .optional()
        .transform(x => x ?? null),
      customerContactEmail: z.string().nullable(),
      isActive: z.boolean(),
      // perms
      permissionAccessTypes: z.string(),
      permissions: z.object(permissionZodObject),
      // concierge
      conciergeApiSettings: z.object({
        includeTotalSpend: z.boolean(),
        includeTableNumbers: z.boolean(),
        includeCheckNumbers: z.boolean(),
        conciergeReservationHolds: z.boolean(),
        includePosTickets: z.boolean(),
        conciergeBypassPayment: z.boolean(),
      }),
      rateLimitMultiplier: z.number().nullable(),
      // webhook
      webhookEnabled: z.boolean(),
      webhookGenerateHeaderToken: z.boolean(),
      useSchemaValidation: z.boolean(),
      webhookLeverageStaticIpProxy: z.boolean(),
      webhookResolveLinkedEntities: z.boolean(),
      webhookUrl: z.string().nullable(),
      webhookHeaders: z.string(),
      webhookExcludeHeaders: z.string().nullable(),
      webhookPriorityQueue: z.string().nullable(),
      webhookApiVersion: z.string().nullable(),
      webhookBatchSize: z.number().nullable(),
      webhookMaxProcessBatchSize: z.number().nullable(),
      webhookSubscribedEntityTypes: z.array(z.string()),
      webhookSubscribedEventTypes: z.array(z.string()),
    })
    .superRefine((input, context) => {
      let ok = true
      if (!input.apiIntegrationType) {
        context.addIssue({
          code: z.ZodIssueCode.custom,
          message: 'Integration Type is required!',
          path: ['apiIntegrationType'],
        })
        ok = false
      }

      if ((input.apiIntegrationType === '3RD_PARTY' || input.apiIntegrationType === 'POS') && !input.apiIntegrationTypeId) {
        context.addIssue({
          code: z.ZodIssueCode.custom,
          message: 'Integration Type ID is required!',
          path: ['apiIntegrationTypeId'],
        })
        ok = false
      }

      if (input.apiIntegrationType === '2ND_PARTY' && !input.integrationOwner) {
        context.addIssue({
          code: z.ZodIssueCode.custom,
          message: 'Integration Owner is required!',
          path: ['integrationOwner'],
        })
        ok = false
      }

      return ok
    })
  type editAudienceFormType = z.infer<typeof editAudienceForm>

  const defaultValues = useMemo(
    () => ({
      name: data.name,
      isActive: data.isActive,
      customerContactEmail: data.customerContactEmail,
      permissionAccessTypes: formData.hasAllPermissions ? 'ALL' : 'CUSTOM',
      permissions: defaultPermissions,
      conciergeApiSettings: {
        includeTotalSpend: data.conciergeApiSettings.includeTotalSpend,
        includeTableNumbers: data.conciergeApiSettings.includeTableNumbers,
        includeCheckNumbers: data.conciergeApiSettings.includeCheckNumbers,
        conciergeReservationHolds: data.conciergeApiSettings.conciergeReservationHolds,
        includePosTickets: data.conciergeApiSettings.includePosTickets,
        conciergeBypassPayment: data.conciergeApiSettings.conciergeBypassPayment,
      },
      rateLimitMultiplier: data.rateLimitMultiplier,
      webhookEnabled: data.webhookEnabled,
      webhookUrl: data.webhookUrl,
      webhookHeaders: data.webhookHeaders,
      webhookBatchSize: data.webhookBatchSize,
      webhookMaxProcessBatchSize: data.webhookMaxProcessBatchSize,
      webhookApiVersion: data.webhookApiVersion,
      webhookSubscribedEntityTypes: data.webhookSubscribedEntityTypes,
      webhookSubscribedEventTypes: data.webhookSubscribedEventTypes,
      useSchemaValidation: data.useSchemaValidation,
      webhookGenerateHeaderToken: data.webhookGenerateHeaderToken,
      webhookExcludeHeaders: data.webhookExcludeHeaders,
      webhookResolveLinkedEntities: data.webhookResolveLinkedEntities,
      webhookLeverageStaticIpProxy: data.webhookLeverageStaticIpProxy,
      webhookPriorityQueue: data.webhookPriorityQueue,
      apiIntegrationType: data.apiIntegrationType,
      apiIntegrationTypeId: data.apiIntegrationTypeId,
      integrationOwner: data.integrationOwner,
    }),
    [data, formData, defaultPermissions]
  )

  const form = useForm(editAudienceForm, {
    defaultValues,
    mode: 'onChange',
  })
  const { field, handleSubmit } = form
  const [revealSecret, setRevealSecret] = useState(false)
  const [showCustomPermissions, setShowCustomPermissions] = useState(!formData.hasAllPermissions)
  const isConcierge = ['CONCIERGE', 'CONCIERGE_GROUP'].indexOf(data.entityType) > -1

  const permissionOptions: SelectOption[] = [
    {
      id: `ALL`,
      label: 'ALL',
    },
    {
      id: `CUSTOM`,
      label: 'CUSTOM',
    },
  ]

  const apiVersionsOptions: SelectOption[] = formData.apiVersions.map(version => ({
    id: version,
    label: version,
  }))

  const webhookSubscribedEntityTypeChoices: SelectOption[] = formData.webhookSubscribedEntityTypeChoices.map(s => ({
    id: s,
    label: s,
  }))

  const webhookSubscribedEventTypeChoices: SelectOption[] = formData.webhookSubscribedEventTypeChoices.map(s => ({
    id: s,
    label: s,
  }))

  const onSubmit = async (data: editAudienceFormType) => {
    const sendData = { ...data }
    if (data.permissionAccessTypes === 'ALL') {
      sendData.permissions = {
        resourceOperationBlacklist: [],
        resourceOperationWhitelist: [],
        ...{ read: data.permissions.read, write: data.permissions.write },
      }
    }
    updateClientApp({ clientAppId, data: sendData as unknown as ClientApp })
  }

  const getIntegrationTypesQuery = useGetIntegrationTypesQuery()

  const [updateIntegrationType] = useWatchMany(field, ['apiIntegrationType'])
  const isPosOr3rdParty = updateIntegrationType && ['3RD_PARTY', 'POS'].includes(updateIntegrationType)
  const is2ndParty = updateIntegrationType && ['2ND_PARTY'].includes(updateIntegrationType)

  const isLegacy = updateIntegrationType && ['LEGACY'].includes(updateIntegrationType)
  const is7xExcusive = updateIntegrationType && ['7X_EXCLUSIVE'].includes(updateIntegrationType)

  if (isLegacy) {
    integrationTypesConstOptions.push({ id: 'LEGACY', label: 'LEGACY' })
  }
  if (is7xExcusive) {
    integrationTypesConstOptions.push({ id: '7X_EXCLUSIVE', label: '7X EXCLUSIVE' })
  }

  const { data: integrationTypesData } = getIntegrationTypesQuery

  const integrationTypesOptions = integrationTypesData
    ? integrationTypesData
        .filter(item => item.type === updateIntegrationType)
        .map(item => ({
          label: item.name,
          id: item.id,
        }))
    : []
  const disabledTypeChoice = (data.apiIntegrationType && ['3RD_PARTY', 'POS'].includes(data.apiIntegrationType)) || is7xExcusive

  return (
    <VStack>
      <form onSubmit={handleSubmit(onSubmit)}>
        <Header type="h2">Credentials</Header>
        <HStack>
          <Box minWidth="200px">Client ID</Box>
          <Box>
            <pre style={{ lineBreak: 'anywhere', whiteSpace: 'pre-wrap' }}>{data.clientId}</pre>{' '}
            <Button data-test="copy-client-id" onClick={() => copyToClipboard(data.clientId)} size="s">
              Copy
            </Button>
          </Box>
        </HStack>
        <HStack>
          <Box minWidth="200px">Client Secret</Box>
          <Box>
            <pre style={{ lineBreak: 'anywhere', whiteSpace: 'pre-wrap' }}>
              {revealSecret ? data.clientSecret : `${data.clientSecret.substring(0, 10)}...${data.clientSecret.slice(-10)}`}
            </pre>
            <HStack width="150px" justifyContent="space-between">
              <Button data-test="reveal-secret-button" size="s" onClick={() => setRevealSecret(!revealSecret)}>
                {revealSecret ? 'Hide' : 'Reveal'}
              </Button>
              <Button data-test="copy-to-clipboard-button" size="s" onClick={() => copyToClipboard(data.clientSecret)}>
                Copy
              </Button>
            </HStack>
          </Box>
        </HStack>
        <Header type="h2">General</Header>

        <Box minHeight="90px">
          <HStack>
            <Label width="100%" primary="Integration type">
              <FormSelect
                withEmpty
                hideEmptyOption
                disabled={!!disabledTypeChoice}
                field={field.prop('apiIntegrationType')}
                placeholder="Please, select value"
                options={integrationTypesConstOptions}
                searchable={false}
              />
            </Label>
            {isPosOr3rdParty && (
              <Label width="100%" primary="&nbsp;">
                <FormSelect
                  withEmpty
                  hideEmptyOption
                  field={field.prop('apiIntegrationTypeId')}
                  placeholder="Please, select value"
                  options={integrationTypesOptions}
                  searchable
                />
                <Link target="_blank" to="/admin/api-integration-types">
                  Manage types
                </Link>
              </Label>
            )}
          </HStack>
        </Box>
        <Box minHeight="90px">
          <Label primary="Name">
            <FormInput fullWidth field={field.prop('name')} disabled={!!is7xExcusive} />
          </Label>
        </Box>
        {is2ndParty && (
          <Label primary="Integration Owner">
            <FormInput fullWidth field={field.prop('integrationOwner')} />
          </Label>
        )}

        <Box minHeight="90px">
          <Label primary="Customer Contact Email">
            <FormInput fullWidth field={field.prop('customerContactEmail')} />
          </Label>
        </Box>
        <HStack minHeight="40px">
          <Checkbox id="isActive" field={field.prop('isActive')} />
          <Label htmlFor="isActive" primary="Active" />
        </HStack>
        <Header type="h2">Permissions</Header>
        <HStack justifyContent="space-between">
          <HStack width="50%">
            <Checkbox field={field.prop('permissions.read')} />
            <Label primary="Read Permission" />
          </HStack>
          <HStack width="50%">
            <Checkbox field={field.prop('permissions.write')} />
            <Label primary="Write Permission" />
          </HStack>
        </HStack>
        <Box minHeight="90px">
          <Label primary="Permissions Access Type">
            <FormSelect
              field={field.prop('permissionAccessTypes')}
              placeholder="Permissions Access Type"
              options={permissionOptions}
              searchable={false}
              onChange={v => {
                setShowCustomPermissions(v === 'CUSTOM')
              }}
            />
          </Label>
        </Box>
        <Box>
          {showCustomPermissions &&
            Object.values(permissionKeyMap).map(item => (
              <div key={item.id} style={{ paddingTop: item.isHeader ? '20px' : 0, display: 'flex' }}>
                <div
                  style={{
                    minWidth: '300px',
                    maxWidth: '400px',
                    paddingLeft: !item.isHeader ? '5px' : '0',
                    paddingRight: item.isHeader ? '5px' : 0,
                  }}
                >
                  <Label htmlFor={item.id} primary={item.label} />
                </div>
                <Checkbox id={item.id} field={field.prop(`permissions.${item.id}`)} />
              </div>
            ))}
        </Box>
        {isConcierge && (
          <>
            <Header type="h2">Concierge API Settings</Header>
            <HStack minHeight="40px">
              <Checkbox id="webhookEnabled" field={field.prop('conciergeApiSettings.conciergeReservationHolds')} />
              <Label htmlFor="webhookEnabled" primary="Concierge Reservation Holds Enabled" />
            </HStack>
            <HStack minHeight="40px">
              <Checkbox id="webhookEnabled" field={field.prop('conciergeApiSettings.includeTotalSpend')} />
              <Label htmlFor="webhookEnabled" primary="Include total spend" />
            </HStack>
            <HStack minHeight="40px">
              <Checkbox id="webhookEnabled" field={field.prop('conciergeApiSettings.includeTableNumbers')} />
              <Label htmlFor="webhookEnabled" primary="Include table numbers" />
            </HStack>
            <HStack minHeight="40px">
              <Checkbox id="webhookEnabled" field={field.prop('conciergeApiSettings.includeCheckNumbers')} />
              <Label htmlFor="webhookEnabled" primary="Include check numbers" />
            </HStack>
            <HStack minHeight="40px">
              <Checkbox id="webhookEnabled" field={field.prop('conciergeApiSettings.includePosTickets')} />
              <Label htmlFor="webhookEnabled" primary="Include pos tickets" />
            </HStack>
            <HStack minHeight="40px">
              <Checkbox id="webhookEnabled" field={field.prop('conciergeApiSettings.conciergeBypassPayment')} />
              <Label htmlFor="webhookEnabled" primary="Concierge Bypass Add Charges Validation" />
            </HStack>
            <Box minHeight="110px">
              <Label primary="Concierge API Rate limit multiplier" secondary="Restrict: 0, Default: 1, Range: 0-100, Step: 0.1">
                <FormNumberInput fullWidth field={field.prop('rateLimitMultiplier')} />
              </Label>
            </Box>
          </>
        )}
        <Header type="h2">Webhook Configuration</Header>
        <HStack minHeight="40px">
          <Checkbox id="webhookEnabled" field={field.prop('webhookEnabled')} />
          <Label htmlFor="webhookEnabled" primary="Webhook Enabled" />
        </HStack>
        <Box minHeight="90px">
          <Label primary="Webhook URL">
            <FormInput fullWidth field={field.prop('webhookUrl')} />
          </Label>
        </Box>
        <Box minHeight="110px">
          <Label primary="Webhook Custom Headers" secondary="JSON - E.g. Authorization:HelloMoto##OtherHeader:HelloWorld">
            <FormInput fullWidth field={field.prop('webhookHeaders')} />
          </Label>
        </Box>
        <Box minHeight="110px">
          <Label primary="Webhook Message Batch Size" secondary="Default: 200">
            <FormNumberInput fullWidth max={200} greaterThanZero field={field.prop('webhookBatchSize')} />
          </Label>
        </Box>
        <Box minHeight="110px">
          <Label
            primary="(DO NOT USE) Webhook Process batch size"
            secondary="Default 500; Use this setting to lower the processed batch on 7R to avoid runners be killed by OOM error"
          >
            <FormNumberInput fullWidth field={field.prop('webhookMaxProcessBatchSize')} />
          </Label>
        </Box>
        <Box minHeight="90px">
          <Label primary="Webhook API Version">
            <FormSelect field={field.prop('webhookApiVersion')} withEmpty options={apiVersionsOptions} />
          </Label>
        </Box>
        <Box minHeight="90px">
          <Label primary="Webhook Subscribed Entity Types">
            <FormMultiSelect field={field.prop('webhookSubscribedEntityTypes')} options={webhookSubscribedEntityTypeChoices} />
          </Label>
        </Box>
        <Box minHeight="90px">
          <Label primary="Webhook Subscribed Event Types">
            <FormMultiSelect field={field.prop('webhookSubscribedEventTypes')} options={webhookSubscribedEventTypeChoices} />
          </Label>
        </Box>
        <HStack minHeight="40px">
          <Checkbox id="useSchemaValidation" field={field.prop('useSchemaValidation')} />
          <Label htmlFor="useSchemaValidation" primary="Enable marshmallow schema validations" />
        </HStack>
        <HStack minHeight="40px">
          <Checkbox id="webhookGenerateHeaderToken" field={field.prop('webhookGenerateHeaderToken')} />
          <Label htmlFor="webhookGenerateHeaderToken" primary="Webhook Generate Authorization token Enabled" />
        </HStack>
        <Box minHeight="90px">
          <Label primary="Webhook Exclude Headers" secondary="E.g. Authorization,Content-Type">
            <FormInput fullWidth field={field.prop('webhookExcludeHeaders')} />
          </Label>
        </Box>
        <HStack minHeight="40px">
          <Checkbox id="webhookResolveLinkedEntities" field={field.prop('webhookResolveLinkedEntities')} />
          <Label
            htmlFor="webhookResolveLinkedEntities"
            primary="Webhook Resolve Linked Entities"
            secondary="If set to true, each Reservation entity will also serialize the connected Client data (client_id, source_client_id references) as
          part of the payload"
          />
        </HStack>
        <HStack minHeight="40px">
          <Checkbox id="webhookLeverageStaticIpProxy" field={field.prop('webhookLeverageStaticIpProxy')} />
          <Label
            htmlFor="webhookLeverageStaticIpProxy"
            primary="Leverage the static IP proxy relay"
            secondary=" If set to true, send the webhook to NAP (Static IP) proxy cloud function and move the desired url to the x-proxy-target header
          instead: 35.192.236.72, 35.224.204.179, 35.232.46.229, 104.197.186.112"
          />
        </HStack>
        <Box minHeight="90px">
          <Label primary="Webhook Priority Queue Name">
            <FormSelect
              options={[{ id: 'webhooks-queue-priority', label: 'webhooks-queue-priority' }]}
              field={field.prop('webhookPriorityQueue')}
              withEmpty
            />
          </Label>
        </Box>
        <Button data-test="client-app-save-button" disabled={isUpdatingClientApp} variant="primary" type="submit">
          Save
        </Button>
      </form>
    </VStack>
  )
}

export function ClientAppEdit() {
  const nav = useNavigation()
  const params = nav.matchParams(routes.admin.clientappsEdit)

  const { clientAppId } = params as { clientAppId: string }

  const getDataQuery = useGetClientAppQuery({ clientAppId })
  const getDataFormQuery = useGetClientAppFormQuery({ clientAppId })

  const { data, isError } = getDataQuery
  const { data: dataForm, isError: isErrorForm } = getDataFormQuery

  if (!data || !dataForm || isError || isErrorForm) {
    return <Loader />
  }

  return (
    <>
      <ClientAppEditForm data={data} formData={dataForm} clientAppId={clientAppId} />
    </>
  )
}
