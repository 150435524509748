import { render } from 'react-dom'
import { AdminPage } from '@sevenrooms/admin'
import { svrExport } from '@sevenrooms/core/utils'
import { AdminManageBlocklist } from './components'

function renderManageBlocklist(elementId: string) {
  return render(
    <AdminPage>
      <AdminManageBlocklist />
    </AdminPage>,
    document.getElementById(elementId)
  )
}

svrExport('AdminManageBlocklist', 'render', renderManageBlocklist)
