import { render } from 'react-dom'
import { AdminPage } from '@sevenrooms/admin'
import { Switch, Route } from '@sevenrooms/core/navigation'
import { svrExport } from '@sevenrooms/core/utils'
import { routes } from '@sevenrooms/routes'
import { ApiAudienceManager, ClientAppEdit } from './components'

function renderApiAudienceManager(elementId: string) {
  return render(
    <AdminPage>
      <Switch>
        <Route path={routes.admin.clientappsManager.path}>
          <ApiAudienceManager />
        </Route>

        <Route path={routes.admin.clientappsEdit.path}>
          <ClientAppEdit />
        </Route>
      </Switch>
    </AdminPage>,
    document.getElementById(elementId)
  )
}

svrExport('ApiAudienceManager', 'render', renderApiAudienceManager)
